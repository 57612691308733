.MealPlanWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFF;
    gap: 35px;
    background-color: #2D2D2D;
    width: fit-content;
    max-width: 100%;
    padding: 40px;
    padding-bottom: 40px;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 40px;
}

.Line{
	height: 1px;
	background-color: #FFF;
	width: 75%;
	margin: auto;
	border-radius: 5px;
}

.MealType{
	font-weight: 500;
}

.Meals {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    max-width: 280px;
    margin: auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .MealPlanWrapper {
        padding: 15px;
        gap: 20px;
    }

    .Meals {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .MealPlanWrapper {
        padding: 10px;
        gap: 25px;
    }

    .Meals {
        gap: 8px;
    }
}
