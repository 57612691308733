.SecondaryButton {
	background-color: transparent;
	border: 0.5px #606060 solid;
	border-radius: 5px;
	padding: 10px 20px;
	color: #B1AAAA;
	cursor: pointer;
	transition: all 1s;
}

.SecondaryButton:hover {
	background-color: #3b4139;
	color: #B1AAAA;
}

@media (max-width: 600px) {
	.SecondaryButton span {
		font-size: 0.8em;
	}

	.SecondaryButton {
		padding: 5px 10px;
	}
}