.MainDropdownWrapper {
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100px;
	max-width: 190px;
}

.MainDropdown {
	background-color: transparent;
	border: none;
	padding: 12px 5px 12px 5px;
	border-radius: 5px;
	color: #706e6e;
}

@media (max-width: 600px) {
	.MainDropdownWrapper {
		width: 75px;
		font-size: 0.7em;
	}

	.MainDropdown{
		font-size: 0.8em;
	}
}