.RoutineTimerWrapper {
	display: flex;
	flex-direction: row;
	gap: 30px;
	align-items: center;
}

.RoutinePlayIcon {
	width: 18px;
}

.RoutineTimerIcon {
	width: 25px;
}

.RoutineTimerText {
	color: #B4B4B4;
	font-size: 1.5em;
	font-weight: 200;
}

.RoutineResetIcon {
	width: 25px;
}

.RoutineTimerActions {
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.RoutineTimer {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

@media (max-width: 600px) {
	.RoutineTimerText {
		font-size: 1.2em;
	}

	.RoutinePlayIcon,
	.RoutinePauseIcon,
	.RoutineTimerIcon,
	.RoutineResetIcon {
		height: 15px;
	}

	.RoutineTimerActions {
		gap: 5px;
	}

	.RoutineTimerWrapper{
		gap: 15px;
	}

}