.CardsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 50px;
	margin: auto;
	justify-content: center;
	width: 100%;
}

.UserRoutines {
	display: flex;
	flex-direction: column;
	gap: 100px;
}

.ProfilePage {
	display: flex;
	flex-direction: column;
	gap: 80px;
	max-width: 960px;
	margin: auto;
}

.ProfileAttributesWrapper{
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.EmptySpan {
	display: flex;
	justify-content: center;
	color: #BFBCBC;
}

.ProfileInfo {
	display: flex;
	flex-direction: row;
	gap: 50px;
	align-items: center;
}

.ProfileInfoDetails{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.CardsWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.ProfileInfoWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: left;
	/* width: 60%; */
}

.ProfileRank{
	color: #767676;
	font-size: 0.85em;
}

.ProfileInfoText {
	display: flex;
	flex-direction: row;
	text-align: left;
	gap: 10px;
}

.ProfileInfoAttributesWrapper {
	display: flex;
	flex-direction: row;
	gap: 50px;
}

.ProfileAttribute {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 5px;
}

.ProfilePicture {
	display: flex;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	margin: 0;
}

.ProfileInfoUsername {
	color: #8A8888;
	font-size: 1.2em;
	font-weight: 600;
}

.ProfileAttributeHeading {
	color: #BFBCBC;
	font-size: 0.8em;
}

.ProfileCount {
	color: #656565;
	font-weight: 600;
	font-size: 1.5em;
}

.UserInterests {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
}

.ProfileUserStats {
	display: flex;
	flex-direction: column;
	justify-content: left;
	text-align: left;
	align-items: left;
	gap: 50px;
}

.EmptyText{
	color: #BFBCBC;
	width: 100%;
	display: block;
}

/* .CardsContainer span{
} */

@media (max-width: 600px) {
	.CardsContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.ProfileInfoWrapper {
		flex-direction: column;
		gap: 40px;
		width: 100%;
	}

	.ProfileAttribute {
		text-align: center;
	}

	.ProfileInfoAttributesWrapper {
		justify-content: center;
	}

	.SectionTitle {}

	.ProfileInfo {
		flex-direction: column;
	}

	.ProfileInfoUsername{
		text-align: center;
	}
}