.ProgressBarWrapper {
	display: flex;
	align-items: center;
	/* Add any additional styles you need for the wrapper */
}

.ProgressBar {
	width: 50%;
	background-color: #0d0d0d;
	height: 5px;
	border-radius: 99px;
	overflow: hidden;
	/* Ensure the progress doesn't overflow the container */
}

.ProgressFill {
	background-color: #393D1B;
	height: 100%;
	border-radius: inherit;
	transition: width 0.3s ease-in-out;
}

@media (max-width: 1000px) {
	.ProgressBar{
		margin: auto;
	}
}