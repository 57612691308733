.AddChallengeCard {
	display: flex;
	flex-direction: column;
	width: 80%;
	max-width: 640px;
	margin: auto;
	margin-top: 35px;
}

.AddChallengeCardContent {
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
}

.AddChallengeCardWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	/* padding: 15px; */
	border-radius: 10px;
}

.OptionsButton {
	background-color: #F5F7F4;
	padding: 5px 20px;
	border-radius: 10px;
}

.WorkoutInput {
	background-color: transparent;
	border: none;
	border: 1px solid #606060;
	border-radius: 5px;
	height: fit-content;
	padding: 10px;
	width: 35px;
	color: #646464;
}

.InputContainer {
	display: flex;
	flex-direction: row;
	gap: 5px;
	justify-content: space-between;
	align-items: center;
}

.InputButton {
	background: transparent;
	border: none;
	color: #B1AAAA;
	font-size: 1.5em;
	font-weight: 100;
}

.InputButton:hover {
	background-color: #2D2D2D;
	border-radius: 2px;
}


.WorkoutName {
	background-color: #D0DDCA;
	padding: 10px;
	border-radius: 5px;
}

.WorkoutInputWrapper {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.DropdownIcon {
	display: flex;
	width: 25px;
	margin-right: 10px;
}

.WorkoutsCards {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
}

.AddChallengeCardActions {
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.DropdownIconWrapper {
	width: 50px;
}

.ProfileInfo{
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.ProfileInfoDetails{
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.WorkoutType{
	color: #858383;
	font-size: 0.9em;
}

@media (max-width: 600px) {
	.AddChallengeCard {
		width: 95%;
	}

	.AddChallengeCardWrapper {
		flex-direction: column;
		padding: 5px;
		gap: 10px;
	}

	.WorkoutInput {
		width: 20px;
		padding: 5px;
		font-size: 0.6em;
	}

	.InputButton{
		height: fit-content;
		font-weight: 200;
		font-size: 1em;
	}

	.AddChallengeCardContent{
		gap: 10px;
		justify-content: space-between;
	}

	.WorkoutInputWrapper{
		gap: 10px;
		
	}
}