.RoutineCompleteModal {
	width: 100%;
	height: 100vh;
}

.RoutineCompleteWrapper {
	/* Your existing styles */
	display: flex;
	flex-direction: column;
	background-color: #2C2C2C;
	padding: 20px;
	gap: 20px;
	margin: auto;
	/* Set a maximum height for the modal content */
	max-height: 70vh;
	/* Adjust this value as needed */
	/* Enable vertical scrolling */
	overflow-y: auto;
	/* Hide horizontal scrollbar if content doesn't overflow */
	overflow-x: hidden;
}

.ModalButtons {
	/* Your existing styles */
	display: flex;
	flex-direction: row;
	margin: auto;
	gap: 45px;
}

.RoutineCompletedHeading {
	display: flex;
	flex-direction: column;
}

.RoutineCupIcon {
	width: 28px;
}

.RoutineTextTitle {
	color: #808080;
}

.RoutineText {
	color: #898989;
	font-weight: 200;
}