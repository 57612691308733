.NavBar{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: 1280px;
	margin: auto;
	margin-bottom: 40px;
}

.NavBarLogo{
	width: 25px;
}

.UserNavInfo {
	display: flex;
	flex-direction: row;
	gap: 10px;
	text-align: center;
	justify-content: center;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	font-size: 0.8em;
	color: #858383;
	align-items: center;
}

.NavLogoContainer{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: auto;
}

.NavLogoWrapper{
	display: flex;
	flex-direction: row;
	gap: 35px;
}

.Username{
	height: fit-content;
}

.UserNavWithUpgrade{
	display: flex;
	flex-direction: row;
	gap: 30px;
}


@media (max-width: 600px) {
	.NavBarLogo{
		width: 18px;
	}
}