.radio {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 0.9em;
}

.radioBox {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 20px;
	padding: 8px 25px;
	border: 1px solid #606060;
	color: #858383;
	border-radius: 5px;
	;
}

.radioLabel {}

.checked .radioBox {
	border: 1px solid #315A3E;
}

.checked .radioBox:after {
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 3px auto;
}

@media (max-width: 600px) {
	.radio {
		font-size: 0.8em;
	}

	.radioBox {
		padding: 4px 10px;
	}
}