.checkbox {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	justify-content: center;
	margin: auto;
	margin-bottom: 15px;
}

.checkboxBox {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 2px 30px;
	border-radius: 15px;
	border: 1px solid #606060;
	margin: auto;
	display: inline-block;
	justify-content: center;
	align-items: center;
}

.checkboxLabel {
	font-size: 0.9em;
	color: #858383;
}

.checked .checkboxBox {
	border: 1px solid #315A3E;
}

@media (max-width: 600px) {
	.checkboxBox {
		padding: 2px 10px;
	}

	.checkbox {
		font-size: 0.8em;
	}
}