.AttributeSelectionWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.AttributeSelection {
	display: flex;
	flex-direction: row;
	gap: 40px;
	margin: auto;
}

.AttributeSection {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.AttributeSectionHeading {
	font-size: 1em;
	font-weight: 600;
	color: #939393;
}

.AddRoutinePage {
	display: flex;
	flex-direction: column;
	gap: 60px;
}

.ButtonsWrapper {
	display: flex;
	flex-direction: row;
	margin: auto;
	width: 40%;
	justify-content: space-between;
}

.CardsWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 15px;
}

.QuestionHeading {
	opacity: 0.7;
	color: #F5F5F5;
}

.AddChallengeCardOptions {
	width: 100%;
	max-width: 640px;
	margin: auto;
	display: flex;
	flex-direction: row;
	gap: 30px;
}

.OptionsButton {
	background-color: #F5F7F4;
	padding: 5px 20px;
	border-radius: 10px;
	border: 0;
}

@media (max-width: 600px) {
	.QuestionHeading {
		font-size: 0.8em;
	}
}