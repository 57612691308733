.WorkoutCardSelected {
	background-color: #2d2d2d;
	max-width: 640px;
	padding: 10px 20px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.WorkoutName {
	color: #545454;
}

.WorkoutFrequency {
	color: #545454;
}

.WorkoutInfo {
	display: flex;
	flex-direction: row;
	gap: 50px;
}

.CheckIcon {
	width: 25px;
	margin: 10px;
}

.WorkoutCard {
	background-color: transparent;
	max-width: 640px;
	padding: 10px 20px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 600px) {
	.WorkoutName {
		font-size: 0.8em;
	}
}