.RoutineMemberCard {
	display: flex;
	flex-direction: row;
	font-size: 1.1em;
	gap: 20px;
}

.RoutineMemberImage{
	margin: 0;
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.RoutineMemberName {
	color: #B1AAAA;
}

.RoutineMemberScore {
	color: #595959;
}