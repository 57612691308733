.StrydCardsWrapper {
	display: grid;
	grid-template-columns: 420px 420px;
	gap: 65px;
	margin: auto;
}

.GeneratedStryds {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.ButtonsWrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
}

.GeneratedStrydsWrapper {
	display: flex;
	flex-direction: column;
	gap: 150px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	margin: 100px auto;
}

.PageTitle {
	color: #545454;
	font-weight: 400;
	font-size: 1.2em;
	margin: auto;
}

.PageDescription {
	color: #999D93;
}

.PageHeadingWrapper {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 10px;
}

.LoadingSpinner {
	display: flex;
	flex-direction: column;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	text-align: center;
	justify-content: center;
	margin: auto;
	gap: 10px;
	margin-top: 20px;
}

.RoutineWrapper{
	background-color: #232323;
	border-radius: 7px;
	border: 0.5px solid #515151;
	padding: 50px;
	margin: auto;
	display: flex;
	flex-direction: column;
	gap: 50px;
	width: 600px;
}

.RoutineTimeWrapper{
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}

.RoutineText{
	color: #858383;
	font-weight: 200;
}

.TimeIcon{
	width: 20px;
}

.RoutineExplanation{
	color: #565656;
	font-size: 1em;
}

.RoutineWorkouts{
	display: flex;
	justify-content: space-between;
	gap: 20px;
	color: #616161;
	font-size: 1.1em;
}

.RoutineWorkoutsNumber{
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.RoutineWorkoutsWrapper{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.RoutineNameWrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	text-align: left;
}

.RoutineName {
	color: #858383;
	font-size: 1.1em;
}

.RoutineUserName {
	color: #908E8D;
	font-weight: 200;
}

.RoutineDetailsWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

img {
	margin: auto;
}

.RoutineGenerationText{
	display: flex;
	color: #FFF;
	flex-direction: column;
	gap: 20px;
}

.RoutineGenerationSpinner{
	width: 50px;
}

@media (max-width: 600px) {
	.StrydCardsWrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.RoutineWrapper{
		padding: 20px;
		width: fit-content;
	}
	
	.RoutineName{
		font-size: 0.9em;
	}

	.TimeIcon{
		width: 12px;
	}

	.RoutineExplanation{
		font-size: 0.9em;
	}

	.RoutineWorkouts span {
		font-size: 0.8em;
		text-align: left;
	}

	.RoutineText{
		font-size: 0.9em
	}

	.PageTitle {
		font-size: 1.1em;
	}

	.PageDescription {
		font-size: 0.8em;
	}

	.PageHeadingWrapper {
		text-align: center;
	}
}