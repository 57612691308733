.PrimaryButton {
	border-radius: 5px;
	background: #393D1B;
	border: none;
	padding: 10px 20px;
	color: #B1AAAA;
	cursor: pointer;
	transition: all 0.7s;
}

.PrimaryButton:disabled {
	background-color: #F0E2E2;
}

.spinner {
	height: 25px;
}

.PrimaryButton:hover {
	background-color: #35471E;
	color: white;
}

@media (max-width: 600px) {
	.PrimaryButton span {
		font-size: 0.8em;
	}

	.PrimaryButton {
		padding: 5px 10px;
	}
}