.ChallengeCardWrapper {
	display: flex;
	flex-direction: column;
	gap: 25px;
	margin: auto;
	width: 100%;
	max-width: 640px;
}

.ChallengeCard {
	background-color: #383838;
	padding: 20px;
	border-radius: 10px;
}

.ChallengeDetails {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.ChallengeInfo {
	display: flex;
	gap: 25px;
}

.ChallengeName {
	color: #858383;
	font-weight: 600;
	font-size: 1.2em;
	text-align: left;
}

.ChallengeNumber {
	color: #858383;
	font-size: 1.1em;
}

.DemoLink{
	color: #FFF;
}

.arrow {
	width: 25px;
	height: 25px;
	margin: 10px;
}

.WorkoutCards {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 30px;
}

.DeleteButton{
	font-size: 0.8em;
	color: red;
	display: flex;
	justify-content: center;
	align-items: center;
}

.DemoWrapper{
	display: flex;
	justify-content: left;
}

@media (max-width: 600px) {
	.ChallengeCard {
		font-size: 0.8em;
	}

	.DemoLink{
		font-size: 0.7em;
	}
}