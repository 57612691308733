.ButtonWrapper {
	display: flex;
	flex-direction: row;
	margin: auto;
	align-items: center;
	justify-content: center;
	gap: 50px;
}

.ButtonWrapperDescription {
	display: flex;
	flex-direction: column;
	gap: 50px;
}

.PlanConfirmation {
	display: flex;
	flex-direction: column;
	gap: 60px;
}

.PlanConfirmationText {
	color: #FFF;
}

.ButtonWrapperDescription {
	color: #FFF;
}