.StrydCardSmall {
	display: flex;
	flex-direction: row;
	background-color: #2D2D2D;
	padding: 20px 40px;
	/* max-width: 360px; */
	justify-content: space-between;
	border-radius: 7px;
	margin: auto;
	transition: all 0.7s;
}


.StrydCardSmall:hover {
	background-color: #383838;
}

.RoutineNameWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-align: left;
}

.RoutineName {
	color: #d4d4d4;
	font-size: 1em;
	font-weight: 300;
}

.RoutineDetail {
	color: #858383;
	font-weight: 300;
	font-size: 0.9em;
}

.AttributesWrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.TimeIcon {
	width: 20px;
}

.TimeText {
	color: #858383;
	font-weight: 300;
}

.RoutineAtrributesWrapper {
	height: 20px;
}

@media (max-width: 600px) {
	.StrydCardSmall {
		/* width: 70%; */
	}

	.RoutineName {
		font-size: 0.9em;
	}

	.TimeText {
		font-size: 0.8em;
	}
}