.CardsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 35px;
	margin: auto;
	margin-top: 50px;
	justify-content: center;
}

@media (max-width: 600px) {
	.CardsContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}