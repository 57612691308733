.FriendCardWrapper {
	background-color: #2D2D2D;
	border-radius: 10px;
	padding: 20px 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	transition: all 0.7s;
}

.FriendCardWrapper:hover {
	background-color: #383838;
}

.FriendUsername{
	font-size: 1em;
}

.ProfileImage {
	width: 40px;
	height: 40px;
	background-color: #000;
	opacity: 50%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
}

.FriendInfo {
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
	color: #B6B6B6;
	font-size: 1.2em;
}

.FriendPoints {
	color: #808080;
	font-size: 1.1em;
}

.FriendIcon {
	color: #8C8D95;
	font-size: 1.5em;
}


@media (max-width: 600px) {
	.FriendCardWrapper {
		/* width: 80%; */
		padding: 10px 20px;
	}

	.FriendInfo {
		font-size: 1em;
	}
}