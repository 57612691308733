.FormInputWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.InterestSelection {
	display: flex;
	flex-direction: row;
	gap: 40px;
	margin: auto;
}

.QuestionHeading {
	opacity: 0.7;
	color: #646464;
}

.FormTextInput {
	border: 1px solid #606060;
	background-color: transparent;
	border-radius: 5px;
	max-width: 640px;
	width: 80%;
	padding: 5px 5px;
	margin: auto;
	color: #BEBEBE;
}

@media (max-width: 600px) {
	.QuestionHeading {
		font-size: 0.8em;
	}
}