.UpgradeCardWrapper {
	display: flex;
	flex-direction: column;
	background-color: #2A2A2A;
	gap: 80px;
	border-radius: 7px;
	padding: 50px 50px 120px 50px;
	width: 250px;
	border: #515151 solid 1px;
}

.UpgradeCardWrapper button {
	background-color: transparent;
	border: 0.5px #606060 solid;
	border-radius: 5px;
	padding: 10px 20px;
	color: #858383;
	cursor: pointer;
	transition: all 1s;
}


.UpgradeCardWrapper button:hover {
	background-color: #D8EDCD;
	color: #858383;
}

@media (max-width: 600px) {
	.UpgradeCardWrapper {
		gap: 50px;
		padding: 30px 30px 80px 30px;
	}

	.UpgradeCardWrapper button span {
		font-size: 0.5em;
		width: 150px;
		gap: 40px;
	}

	.UpgradeCardWrapper button {
		padding: 5px 10px;
	}
}

.UpgradeCardDetails {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.UpgradeCardFeaturesContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 0.8em;
}

.UpgradeCardName {
	color: #939393;
	font-weight: 600;
	font-size: 0.67em;
}

.UpgradeCardAmount {
	color: #939393;
	font-weight: 600;
	font-size: 1.2em;
}

.UpgradeCardFeature {
	color: #898989;
}