.RoutineInviteWrapper{
	background-color: #2C2C2C;
	border-radius: 7px;
	border: solid 1px #515151;
	width: 480px;
	padding: 40px 40px 100px 40px;
	display: flex;
	flex-direction: column;
	gap: 75px;
}

.RoutineInviteContent{
	display: flex;
	flex-direction: column;
	gap: 35px;
}

.RoutineInviteText{
	color: #444A3B;
	font-size: 1.2em;
	width: 70%;
	margin: auto;
}

.RoutineInviteWrapper button{
	margin: auto;
}