.BottomNav {
	background-color: #000;
	max-width: 800px;
	margin: auto;
	padding: 10px;
	position: fixed;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
	background: rgba(25, 25, 25, 0.86);
	border-radius: 20px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(6.4px);
	-webkit-backdrop-filter: blur(6.4px);
}

.BottomNavWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: auto;
}

.IconButton {
	display: flex;
	background-color: transparent;
	border: none;
	flex-direction: column;
	gap: 5px;
	border-radius: 7px;
	padding: 7px 15px;
	transition: all 1s;
}

.IconButton:hover {
	background-color: #242424;
}

.Icon {
	height: 18px;
}

.ButtonText {
	color: #FFF;
	opacity: 0.6;
	font-size: 0.9em;
}

@media (max-width: 600px) {
	.BottomNav {
		width: 100%;
		bottom: 0;
		padding: 8px;
		border-radius: 0;
	}

	.BottomNavWrapper {
		width: 100%;
	}

	.Icon {
		height: 12px;
	}

	.ButtonText {
		font-size: 0.8em;
	}
}