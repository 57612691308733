.WelcomePage {
	/* width: 100vw; */
	height: 100vh;
	margin: auto;
}

.WelcomePageWrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding-top: 100px;
}

.WelcomeContainer {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 40%;
	margin: auto;
	gap: 20px;
}

.MicrosoftBadge{
	max-width: 170px;
}

.HeadingText {
	color: #636461;
	font-size: 1.5em;
	font-weight: 600;
}

.DescriptionText {
	color: #7C7777;
	font-size: 0.87em;
}

.ParagraphText {
	color: #555555;
	font-size: 0.9em;
}

.ButtonsContainer {
	display: flex;
	flex-direction: row;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 10px auto 75px auto;
	gap: 25px;
}

.MicrosoftBadge {
	width: 28%;
	height: fit-content;
}

@media (max-width: 600px) {
	.WelcomeContainer {
		width: 100%;
	}

	.HeadingText {
		font-size: 1em;
	}

	.ParagraphText {
		font-size: 0.8em;
	}

	.MicrosoftBadge{
		width: 50%;
	}

	.WelcomeIllustration{
		width: 100%;
	}
}

@media (max-width: 900px) {
	.WelcomeContainer {
		width: 70%;
	}

	.HeadingText {
		font-size: 1em;
	}

	.ParagraphText {
		font-size: 0.8em;
	}
}