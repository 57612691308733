.ModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.87);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	/* You might want to ensure the modal is on top of everything */
}

.ModalContent {
	/* Add your existing styles for ModalContent here */
	width: fit-content;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
	background: #000;
	/* Assuming you want it to stand out from the overlay */
	overflow-y: auto;
	/* Ensures only the modal content scrolls */
	max-height: 80vh;
	/* Adjust height as necessary */
	border-radius: 4px;
	/* Just for visual appeal, if desired */
	z-index: 1001;
	/* Above the overlay */
}

.CloseButton {
	/* Assuming you're still using a separate close button */
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	cursor: pointer;
	/* To show it's clickable */
}