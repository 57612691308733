.UpgradePage {
	display: flex;
	flex-direction: column;
	color: #FFF;
	gap: 100px;
}

.UpgradePlansWrapper {
	display: flex;
	margin: auto;
	justify-content: space-between;
	width: 100%;
}

.UpgradePageText {
	display: flex;
	flex-direction: column;
	gap: 35px;
	width: 60%;
	margin: auto;
}

.UpgradePageHeading {
	color: #939393;
	font-size: 1.2em;
}

.UpgradePageTextContent {
	color: #CBCBCB;
}

@media (max-width: 600px) {
	.UpgradePlansWrapper {
		display: flex;
		flex-direction: column;
		margin: auto;
		justify-content: space-between;
		align-items: center;
		gap: 35px
	}

	.UpgradePageTextContent {
		color: #CBCBCB;
	}
}