body {
	margin: 10px;
	font-family: 'Futura PT', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #202020;
}

a {
	text-decoration: none;
	color: inherit;
}

a:visited {
	color: inherit;
}