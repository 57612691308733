.AddBreakCard {
	background-color: #E7F0E2;
	max-width: 640px;
	width: 100%;
	margin: auto;
	text-align: left;
	border-radius: 10px;
}

.AddBreakCardWrapper {
	width: 40%;
	padding: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.WorkoutInput {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #ABB7A5;
	height: fit-content;
	padding: 10px;
	width: 50px;
}