.InterestsPageWrapper {
	display: flex;
	flex-direction: column;
	gap: 75px;
	margin: auto;
}

.InterestsCardWrapper{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.InterestsWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 10px;
	width: 100%;
	margin: auto;
}

.QuestionHeading {
	opacity: 0.7;
	color: #646464;
	margin-bottom: 35px;
}

.HeadingDescription{
	color: #F5F5F5;
	margin-bottom: 75px;
}

.InterestSelectionWrapper {
	display: flex;
	flex-direction: column;
	/* gap: 20px; */
}

.InterestSelection {
	display: flex;
	flex-direction: row;
	gap: 40px;
	margin: auto;
}

.FormTextInput {
	background-color: #E9EDE7;
	border: none;
	max-width: 640px;
	width: 80%;
	padding: 10px;
	margin: auto;
}

.ButtonsWrapper {
	width: 50%;
	margin: auto;
	/* display: flex; */
	flex-direction: row;
	justify-content: space-between;
	margin-top: 75px;
}

.UpgradeInfoWrapper{
	display: flex;
	flex-direction: column;
	gap: 35px;
	color: #F5F5F5;
}

@media (max-width: 600px) {
	.InterestsWrapper {
		grid-template-columns: 1fr 1fr;
	}

	.Heading {
		font-size: 1.3em;
	}

	.HeadingDescription {
		font-size: 0.95em;
		font-weight: 400;
	}

	.QuestionHeading {
		font-size: 0.8em;
		margin-bottom: 15px;
	}

	.InterestSelection {
		gap: 20px;
	}

	.ButtonsWrapper {
		width: 80%;
	}
}