.QuickAccessCard {
	border-radius: 10px;
	padding: 35px 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	transition: all 0.7s;
}

.QuickAccessCardWrapper {
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 70%;
}

.QuickAccessCardIcon {
	width: 22px;
	margin: 0;
}

.QuickAccessCardText {
	color: #F2F2F2;
	font-size: 1em;
}

.QuickAccessCard:hover {
	opacity: 70%;
}

@media (max-width: 600px) {
	.QuickAccessCard {
		/* width: 100px; */
		height: 35px;
		padding: 30px 50px 30px 15px;
	}

	.QuickAccessCardIcon {
		width: 18px;
	}

	.QuickAccessCardText {
		font-size: 0.9em;
	}
}