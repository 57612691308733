.ChoicePage {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin-top: 80px;
}

.Heading {
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100%;
	gap: 15px;
}

.ChoiceCardWrapper {
	display: flex;
	flex-direction: row;
	gap: 35px;
	justify-content: space-between;
	width: 100%;
}

.ChoiceHeading {
	font-weight: 600;
	font-size: 1.5em;
	color: #737373;
}

.ChoiceText {
	color: #A4A4A4;
	font-weight: 300;
	width: 30%;
}

@media (max-width: 600px) {
	.ChoiceCardWrapper {
		flex-direction: column;
	}

	.ChoiceHeading {
		font-size: 1.2em;
	}

	.ChoiceText {
		width: 75%;
	}
}