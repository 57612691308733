.InterestChoiceCardWrapper {
	display: flex;
	flex-direction: column;
	gap: 35px;
	background: #2B2B2B;
	border-radius: 7px;
	border: 0.5px solid #515151;
	width: 250px;
	height: 180px;
	padding: 20px 40px;
}

.Selected {
	background-color: #595959;
	border: none;
}

.ChoiceTitle {
	color: #939393;
	font-size: 1.1em;
	font-weight: 600;
}

.ChoiceText {
	color: #898989;
	font-weight: 300;
}

@media (max-width: 600px) {
	.InterestChoiceCardWrapper {
		width: 120px;
		height: 100px;
		padding: 15px 15px;
	}

	.ChoiceTitle {
		font-size: 0.8em;
	}

	.ChoiceText {
		font-size: 0.7em;
	}
}