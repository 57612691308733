.ChallengeCardsWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 600px;
	margin: auto;
}

.RoutinePageWrapper {
	display: flex;
	flex-direction: row;
	/* width: 90%; */
	justify-content: space-between;
	gap: 50px;
	margin-top: 50px;
}


.RoutineSectionHeading {
	display: block;
	text-align: left;
	color: #444A3B;
	font-size: 1.2em;
	margin-bottom: 40px;
}

.Line{
	background-color: #373a33;
	height: 1px;
}

.RoutineLeaderBoardWrapper{
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-bottom: 40px;
}

.RoutineXPWrapper {
	display: flex;
	flex-direction: row;
	gap: 12px;
	width: fit-content;
	align-items: center;
}

.RoutineXPIcon {
	width: 25px;
}

.RoutineStatsWrapper {
	display: flex;
	flex-direction: row;
	gap: 50px;
}

.FriendCardWrapper {
	display: flex;
	flex-direction: column;
	width: 400px;
	margin: auto;
	gap: 20px;
}

.RoutineInfoWrapper {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 25px;
	margin-bottom: 35px;
}

.RoutineStatText {
	color: #B4B4B4;
	font-size: 1.5em;
	font-weight: 200;
}

.RoutineName {
	color: #B4BFA7;
	font-size: 1.2em;
}

.RoutineInfo {
	color: #B4BFA7;
	font-size: 0.8em;
}

.RoutineDetailsWrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.RoutineProgressWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.RoutineNameWrapper {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.RoutineProgress {
	color: #B4BFA7;
}

.RoutineActionsWrapper {
	display: flex;
	flex-direction: row;
	gap: 35px;
	margin-bottom: 80px;
}

.RoutineCreator {
	color: #FFF;
}

.RoutineCreator:visited {
	color: #FFF;
}

.LoginText{
	color: #FFF;
}

@media (max-width: 600px) {
	.RoutinePageWrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.RoutineXPIcon {
		width: 25px;
	}
}

@media (max-width: 1000px) {
	.RoutinePageWrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.RoutineInfoWrapper {
		text-align: center;
	}

	.ChallengeCardsWrapper {
		width: 100%;
	}

	.ProgressBarWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.RoutineXPIcon {
		height: 18px;
	}

	.RoutineStatText {
		font-size: 1.5em;
	}

	.RoutineXPWrapper {
		gap: 5px;
	}

	.RoutineStatsWrapper {
		justify-content: center;
	}

	.FriendCardWrapper{
		width: 100%;
	}
}

.CopyButton{
	border-radius: 50%;
	width: 40px;
	height: 40px;
	background-color: #2D2D2D;
	border: 0;
	display: flex;
}

.DeleteButton{
	color: crimson;
	background: none;
	border: 0;
	margin: 20px;
}