.ChoiceCard {
	display: flex;
	flex-direction: column;
	background-color: #2B2B2B;
	padding: 40px 0;
	border-radius: 7px;
	border: 0.5px solid #515151;
	align-items: center;
	gap: 50px;
	width: 60%;
	transition: all 1s;
}

.ChoiceTextWrapper {
	display: flex;
	flex-direction: column;
	width: fit-content;
	align-items: center;
	gap: 20px;
}

.ChoiceIcon {
	width: 40px;
	height: 40px;
}

.ChoiceHeading {
	color: #939393;
	font-weight: 500;
	font-size: 1.3em;
}

.ChoiceText {
	color: #898989;
	/* max-width: 300px; */
	padding: 20px;
}

.ChoiceCard:hover {
	background-color: #24241F;
	border: 1px solid #315A3E;
}

@media (max-width: 600px) {
	.ChoiceHeading {
		font-size: 1em;
	}

	.ChoiceCard {
		padding: 20px 0;
		gap: 20px;
		width: 90%;
		margin: auto;
	}

	.ChoiceText {
		font-size: 0.8em;
	}

	.ChoiceIcon {
		width: 35px;
		height: 35px;
	}
}