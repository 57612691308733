.HomePage {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: 100%;
	max-width: 960px;
	margin: auto;
}

.StrydCardsHome {
	width: 100%;
}

.StrydCardsWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.HomeStrydCard {
	background-color: #393D1B;
	width: 100%;
	border-radius: 7px;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.StrydCardsHome :hover {
	background-color: #72815E;
	border-radius: 10px;
	transition: all 0.7s
}

.StrydRoutineInfoWrapper {
	display: flex;
	flex-direction: column;
	text-align: left;
	justify-content: center;
	width: 50%;
	padding-left: 40px;
	gap: 35px;
}

.StrydRoutineInfo {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.StrydRoutineText {
	color: #DDDDDD;
}

.StrydRoutineName {
	color: #FFFEFE;
	font-size: 1.8em;
}

.StrydRoutineCreator {
	color: #F5F6F3;
	font-weight: 200;
	font-size: 0.8em;
}

.CardsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 35px;
	/* margin: auto; */
	justify-content: center;
}

.QuickActionsWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 35px;
	/* margin: auto; */
	justify-content: center;
}

.PlayIcon {
	margin: auto;
}

.HomeTitle {
	color: #444A3B;
	font-size: 1.3em;
	text-align: left;
	width: 100%;
	display: block;
	margin: 50px 0 50px 0;
}

.HomePageContent {
	display: flex;
	flex-direction: column;
	gap: 50px;
	text-align: left;
}

.LinkContainer {
	display: flex;
	justify-content: right;
}

.HomeCards {
	display: flex;
	flex-direction: column;
	gap: 60px;
}

.CardsTitle {
	color: #7a8877;
	font-size: 1.2em;
}

.CardsWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 20px;
}

.UserStryds {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 35px;
}

.LoadingSpinner {
	margin: auto;
}

.HeadingLink {
	text-align: right;
	width: fit-content;
	margin-right: 0;
	display: inline;
	color: #858383;
}

.SectionText {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: auto;
	width: 100%;
	height: fit-content;
}

.EmptyText{
	color: #F5F6F3;
}



@media (max-width: 600px) {
	.StrydCardsHome {
		display: flex;
		flex-direction: column;
	}

	.CardsContainer {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.StrydRoutineText {
		font-size: 0.7em;
	}

	.StrydRoutineCreator {
		font-size: 0.7em;
	}

	.StrydRoutineName {
		font-size: 1.2em;
	}

	.CardsTitle {
		font-size: 0.8em;
	}

	.CardsContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.CardsWrapper {
		width: 100%;
	}

	.HomeTitle {
		font-size: 1.2em;
		margin: 20px 0;
	}

	.HomeStrydCard {
		width: 95%;
		margin: auto;
		padding: 25px 0;
	}

	.StrydRoutineInfoWrapper {
		padding: 10px 20px;
	}

	.UserStryds {
		flex-direction: column;
	}

	.HeadingLink {
		font-size: 0.7em;
	}

	.SectionText {
		width: 100%;
		justify-content: space-around;
	}

	.LinkContainer {
		justify-content: center;
	}

	.PlayIcon {
		width: 35px;
	}
}