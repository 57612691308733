.TagWrapper {
	width: fit-content;
	padding: 3px 12px;
	background-color: #2a2a2a;
	border-radius: 999px;
	color: #FFF;
	margin: auto;
	font-size: 0.8em;
}

@media (max-width: 600px) {
	.TagWrapper {
		font-size: 0.5em;
	}
}