.MealPlansInputWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;
}

.MealPlansWrapper {
	display: grid;
	gap: 50px;
	margin-top: 50px;
}

.MealPlansCards {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 50px;
	margin-bottom: 100px;
}

.MealPlansPageTitle {
	color: #FFF;
}

.EmptyText {
	color: #FFF;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
	.MealPlansWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.MealPlansCards {
		display: flex;
		flex-direction: column;
		gap: 50px;
		margin-bottom: 100px;
	}
}

@media (max-width: 480px) {
	.MealPlansInputWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.MealPlansWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}